// Required imports
import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

// Custom file imports
import SEO from '../components/seo.js'
import { Home as HomeSEO } from '../utils/seo-props.js'
import { StudioLogo } from '../components/logos.js'
import Footer from '../components/footer.js'
import EmailList from '../components/email-list.js'
import AnotherZombieGame from '../components/another-zombie-game.js'
import Guessn from '../components/guessn.js'
import Anchor from '../components/anchor.js'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/font.css';
import '../css/custom.css';

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const ROW_CLASS = 'justify-content-md-center'
const COL_CLASS = 'flex start full-page margin-top sm'

const ROW_PAGE_CLASS = 'justify-content-md-center'
const COL_PAGE_CLASS = 'flex center full-page'

const COL_MD = 'auto'

const SOCIAL_ICON_SIZE = '2x'
const SOCIAL_ICON_CLASSNAME = 'icon social'
const SOCIALS_WRAPPER_CLASS = 'flex center'

const HREF_TWITTER = 'https://twitter.com/leebartondev'
const HREF_YOUTUBE = 'https://www.youtube.com/channel/UCpln3d-gSatALepiOGBx-bg'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const SocialsWrapper = styled.div`
  padding-bottom: 30px;
  margin-top: -20px;
`

export default function Home () {
  return (
    <Container fluid>
      <SEO
        title={HomeSEO.title}
        keywords={HomeSEO.keywords}
      />
      <Row className={ROW_CLASS}>
        <Col md={COL_MD} className={COL_CLASS}>
          <StudioLogo />
          <SocialsWrapper className={SOCIALS_WRAPPER_CLASS}>
            <Nav.Link href={HREF_TWITTER} target='_blank'>
              <FontAwesomeIcon icon={faTwitter} size={SOCIAL_ICON_SIZE} className={SOCIAL_ICON_CLASSNAME} />
            </Nav.Link>
            <Nav.Link href={HREF_YOUTUBE} target='_blank'>
              <FontAwesomeIcon icon={faYoutube} size={SOCIAL_ICON_SIZE} className={SOCIAL_ICON_CLASSNAME} />
            </Nav.Link>
          </SocialsWrapper>
          <EmailList />
          <Anchor anchor='#games'> CHECKOUT OUR PROJECTS </Anchor>
        </Col>
      </Row>
      <Row className={ROW_PAGE_CLASS} id='games'>
        <Col md={COL_MD} className={`${COL_PAGE_CLASS} zombies`}>
          <AnotherZombieGame />
        </Col>
        <Col md={COL_MD} className={`${COL_PAGE_CLASS} guessn`}>
          <Guessn />
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}
