// Required imports
import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'

// Custom file imports
import { GameLogo } from './logos.js'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const BUTTON_CLASS = 'btn-square game-color purp'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const Info = styled.p`
  color: grey;
  margin-top: 30px;
`

// @TODO add link to Steam Wishlist
export default function AnotherZombieGame () {
  return (
    <>
      <GameLogo zombie />
      <Button className={BUTTON_CLASS}>
        Wishlist on Steam!
      </Button>
      <Info> (Currently Not Available) </Info>
    </>
  )
}