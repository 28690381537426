// Required imports
import React, { useState } from 'react'
import styled from 'styled-components'
import addToMailChimp from 'gatsby-plugin-mailchimp'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

// Custom file imports
import Info from '../components/info.js'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const ROW_CLASS = 'align-items-center'
const BUTTON_CLASS = 'btn-square game-color purp'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const Error = styled.p`
  margin-top: 10px;
  margin-bottom: -35px;
  font-size: 1.1rem;
  text-align: center;
  color: red;
`

export default function EmailList () {

  const [email, setEmail] = useState('')

  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const emailSubmit = async event => {
    if (event) { event.preventDefault() }

    const { result } = await addToMailChimp(email.value)
    if (result === 'error') {
      setErrorMessage('Invalid email or the email is already subscribed!')
      setShowSuccess(false)
      setShowError(true)
    } else {
      setShowError(false)
      setShowSuccess(true)
    }
  }

  return (
    <Form onSubmit={emailSubmit}>
      <Info> Get info and stay updated about our projects! </Info>
      {showSuccess
        ? (<Info> Thank you! </Info>)
        : (<Row className={ROW_CLASS}>
            <Col md='auto'>
              <Form.Control
                type='email'
                placeholder='Email Address'
                ref={ref => { setEmail(ref) }}
              />
            </Col>
            <Col>
              <Button
                type='submit'
                className={BUTTON_CLASS}
                onClick={emailSubmit}
              >
                Subscribe!
              </Button>
            </Col>
          </Row>)}
      {showError
        ? (<Error>{ errorMessage }</Error>)
        : null}
    </Form>
  )
}