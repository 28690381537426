// Required imports
import React from 'react'
import Image from 'react-bootstrap/Image'

// Custom file imports
import MyLink from '../components/my-link.js'
import PlayStoreSrc from '../../static/images/play-store-download.png'
import AppStoreSrc from '../../static/images/app-store-download.png'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.novaslothgames.guessn2d';
const APP_STORE_LINK = 'https://apps.apple.com/app/id1625015967';

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export const AppStoreButton = () => (
  <MyLink link tab href={APP_STORE_LINK}>
    <Image className='logo sm' src={AppStoreSrc} />
  </MyLink>
)

export const PlayStoreButton = () => (
  <MyLink link tab href={PLAY_STORE_LINK}>
    <Image className='logo sm' src={PlayStoreSrc} />
  </MyLink>
)