// Required imports
import React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const DIV_CLASS = 'flex center col'
const TITLE_CLASS = 'sloth-font'
const ICON_CLASS = 'animate-up-down'
const ICON_SIZE = '2x'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const AnchorWrapper = styled.a`
  text-decoration: none;
  color: grey;
  &:hover {
    text-decoration: none;
    color: #8968FF;
  }
`

const Title = styled.p`
  font-weight: 600;
  letter-spacing: .2em;
`

export default function Anchor ({ children, anchor }) {
  return (
    <div className={DIV_CLASS}>
      <AnchorWrapper href={anchor}>
          <Title className={TITLE_CLASS}>{children}</Title>
      </AnchorWrapper>
      <FontAwesomeIcon icon={faChevronDown} size={ICON_SIZE} className={ICON_CLASS} />
    </div>
  )
}