/// //////////////////////////////////////////////////////////
// G L O B A L   V A R I A B L E S
/// //////////////////////////////////////////////////////////

// Keywords
const BLOG = 'blog'
const GAME = 'game'
const INDIE_GAME = 'indie game'
const INDIE_DEV = 'indie dev'
const GATSBY = 'gatsby'
const REACT = 'react'
const LANG = 'javascript'
const ANOTHER = 'Another Indie Studio'
const INDIE = 'Indie Studio'
const STUDIO = 'Studio'
const GAME_TITLE = 'Another Zombie Game'
const ZOMBIE_GAME = 'Zombie game'
const ZOMBIE = 'Zombie'
const UNITY = 'Unity'
const UNITY3D = 'Unity3D'
const ENGINE = 'Unity Game Engine'
const GAME_DEV = 'Game Development'
const GAME_DESIGN = 'Game Design'
const BOXHEAD = 'Boxhead'
const BOXHEAD_ROOMS = 'Boxhead: The Rooms'
const COD = 'Call of Duty'
const ZAMN = 'Zombies Ate My Neighbors'
const VOXEL = 'Voxel'
const SURVIVAL = 'Survival'
const ZOMBIE_SURVIVAL = 'Zombie Survival'

// Keyword lists
const BASIC_KEYWORDS = [
  BLOG, GAME, INDIE_GAME, INDIE_DEV, GATSBY, REACT, LANG
]

const HOME_KEYWORDS = BASIC_KEYWORDS.concat([
  ANOTHER, INDIE, STUDIO, GAME_TITLE, ZOMBIE_GAME, ZOMBIE
])

const ABOUT_KEYWORDS = HOME_KEYWORDS.concat([
  UNITY, UNITY3D, ENGINE, GAME_DEV, GAME_DESIGN
])

const PROJECTS_KEYWORDS = HOME_KEYWORDS.concat([
  BOXHEAD, BOXHEAD_ROOMS, COD, ZAMN, VOXEL, SURVIVAL, ZOMBIE_SURVIVAL
])

/// //////////////////////////////////////////////////////////
// S E O   P R O P E R T I E S
/// //////////////////////////////////////////////////////////

// Home page
export const Home = {
  title: 'Home',
  keywords: HOME_KEYWORDS
}

// About page
export const About = {
  title: 'About',
  keywords: ABOUT_KEYWORDS
}

// Projects page
export const Projects = {
  title: 'Projects',
  keywords: PROJECTS_KEYWORDS
}