// Required imports
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Custom file imports
import { GuessnLogo } from "./logos.js";
import {
  AppStoreButton,
  PlayStoreButton
} from '../components/app-buttons.js'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export default function Guessn () {
  return (
    <>
      <GuessnLogo />
      <Row>
        <Col>
          <AppStoreButton />
        </Col>
        <Col>
          <PlayStoreButton />
        </Col>
      </Row>
    </>
  )
}